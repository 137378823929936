<script lang="ts">
    import { twMerge } from 'tailwind-merge';
    import { getContext } from 'svelte';
    import type { FormColorType } from '../types';
    import Label from './Label.svelte';

    // properties forwarding
    export let color: FormColorType = 'primary';
    export let custom = false;
    export let inline = false;
    export let group: (string | number)[] = [];
    export let value: string | number = 'on';
    export let checked: boolean | undefined = undefined;
    export let spacing = 'mr-2';
    export let error = false;

    // tinted if put in component having its own background
    let background: boolean = getContext('background');

    const colorClasses: Record<FormColorType, string> = {
        primary: 'text-primary-600 checked:bg-primary-300 disabled:checked:bg-gray-400 focus:ring-primary-100 dark:focus:ring-primary-600',
        secondary: 'text-secondary-600 focus:ring-secondary-500 dark:focus:ring-secondary-600',
        red: 'text-red-500 border-red-500 border-red-500 rounded checked:bg-red-500 focus:ring-gray-100 dark:focus:ring-red-500',
        green: 'text-green-600 checked:bg-primary-300 disabled:checked:bg-primary-300 focus:ring-gray-100 dark:focus:ring-gray-100',
        purple: 'text-purple-600 focus:ring-purple-500 dark:focus:ring-purple-600',
        teal: 'text-teal-600 focus:ring-teal-500 dark:focus:ring-teal-600',
        yellow: 'text-yellow-400 focus:ring-yellow-500 dark:focus:ring-yellow-600',
        orange: 'text-orange-500 focus:ring-orange-500 dark:focus:ring-orange-600',
        blue: 'text-blue-600 focus:ring-blue-500 dark:focus:ring-blue-600',
    };
    const labelClass = (inline: boolean, extraClass: string) => twMerge(inline ? 'inline-flex' : 'flex', 'items-center', extraClass);
    const inputClass = (custom: boolean, color: FormColorType, rounded: boolean, tinted: boolean, extraClass: string) =>
        twMerge(
            'w-[1.125rem] h-[1.125rem] bg-gray-50 disabled:bg-gray-100 border-gray-200 dark:ring-offset-gray-800 appearance-none border checked:border-none focus:ring shrink-0',
            'cursor-pointer disabled:cursor-not-allowed accent-primary-600',
            spacing,
            tinted ? 'dark:bg-gray-600 dark:border-gray-500' : 'dark:bg-gray-700 dark:border-gray-600',
            custom && 'sr-only peer',
            rounded && 'rounded',
            colorClasses[color],
            extraClass,
            error && colorClasses.red,
        );

    const labelDefaultClass = twMerge('select-none font-normal', checked && 'font-bold', $$props.disabled ? 'cursor-not-allowed' : 'cursor-pointer', $$props.error && 'text-red-500');

    // react on external group changes
    function init(_: HTMLElement, _group: (string | number)[]) {
        if (checked === undefined) checked = _group.includes(value);
        onChange();

        return {
            update(_group: (string | number)[]) {
                checked = _group.includes(value);
            },
        };
    }

    function onChange() {
        // There's a bug in Svelte and bind:group is not working with wrapped checkbox
        // This workaround is taken from:
        // https://svelte.dev/repl/de117399559f4e7e9e14e2fc9ab243cc?version=3.12.1
        const index = group.indexOf(value);

        if (checked === undefined) checked = index >= 0;

        if (checked) {
            if (index < 0) {
                group.push(value);
                group = group;
            }
        } else {
            if (index >= 0) {
                group.splice(index, 1);
                group = group;
            }
        }
    }
</script>

<Label class={labelClass(inline, twMerge(labelDefaultClass, $$props.class))} show={!!$$slots.default}>
    <input
        use:init={group}
        type="checkbox"
        bind:checked
        on:keyup
        on:keydown
        on:keypress
        on:focus
        on:blur
        on:click
        on:mouseover
        on:mouseenter
        on:mouseleave
        on:paste
        on:change={onChange}
        on:change
        {value}
        {...$$restProps}
        class={twMerge(spacing, inputClass(custom, color, true, background, $$slots.default || $$props.class))}
    />
    <slot {checked} />
</Label>

<!--
  @component
  ## Feature
  [Go to Checkbox](https://flowbite-svelte.com/docs/forms/checkbox)
  - Setup
  - Checkbox examples
  - Disabled state
  - Alternative syntax
  - Checkbox with a link
  - Helper text
  - Bordered
  - Checkbox list group
  - Horizontal list group
  - Checkbox dropdown
  - Inline layout
  - Colors
  - Advanced layout
  - Group variable
  ## Props
  @prop color: FormColorType = 'primary';
  @prop custom: boolean = false;
  @prop inline: boolean = false;
  @prop group: (string | number)[] = [];
  @prop value: string | number = 'on';
  @prop checked: boolean | undefined = undefined;
  @prop error: boolean = false;
  ## Event
  - on:keyup
  - on:keydown
  - on:keypress
  - on:focus
  - on:blur
  - on:click
  - on:mouseover
  - on:mouseenter
  - on:mouseleave
  - on:paste
  - on:change
  ## Example
  ```
  <script>
    import { Checkbox } from '$component/basic'
  </script>
  
  <Checkbox>Default checkbox</Checkbox>
  <Checkbox checked>Checked state</Checkbox>
  ```
-->
